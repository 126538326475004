<h1 mat-dialog-title>{{ 'LOAD_SAVED_MATCH.TITLE' | translate }}</h1>

<form>
    <div mat-dialog-content>
        <mat-selection-list
            #savedMatchesList
            [multiple]="false"
            (selectionChange)="loadMatch(savedMatchesList.selectedOptions.selected[0]?.value)"
        >
            <mat-list-option *ngFor="let savedMatch of savedMatches" [value]="savedMatch">
                <div class="d-flex align-items-center">
                    <button mat-mini-fab color="primary">
                        <mat-icon>play_arrow</mat-icon>
                    </button>
                    <span class="ms-2"> {{ savedMatch.name }} ({{ savedMatch.createdAt | luxon: 'DATETIME_SHORT' }}) </span>
                </div>
            </mat-list-option>
        </mat-selection-list>
    </div>

    <div mat-dialog-actions class="float-right">
        <button mat-button mat-dialog-close>{{ 'COMMON.CLOSE' | translate }}</button>
    </div>
</form>
