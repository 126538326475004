<div *ngIf="notification.imageUrl" class="card mx-2 mb-2" [class.clickable]="notification.url" (click)="onNotificationClick()">
    <div class="card-body card-grid">
        <div style="max-width: 60px">
            <img [src]="notification.imageUrl" [alt]="notification.title" class="img-fluid rounded-start" />
        </div>
        <div class="ps-3">
            <div class="card-title mb-0 d-flex justify-content-between">
                <h5 class="mb-0">{{ notification.title }}</h5>
                <small
                    *ngIf="notification.markedAsRead"
                    [matTooltip]="'SIDEBAR.READ_AT_TOOLTIP' | translate: { readAt: notification.readAt | luxon: 'DATETIME_MED' }"
                    class="text-muted"
                    ><mat-icon>visibility</mat-icon></small
                >
            </div>
            <div class="d-flex flex-column">
                <div class="notification-body">{{ notification.body }}</div>
                <small [matTooltip]="notification.createdAt | date: 'short'" class="align-self-end text-muted send-date">{{
                    notification.createdAt | luxon: 'fromNow'
                }}</small>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!notification.imageUrl" class="card mx-2 mb-2" [class.clickable]="notification.url" (click)="onNotificationClick()">
    <div class="card-body">
        <div class="card-title d-flex justify-content-between">
            <h5>{{ notification.title }}</h5>
            <small
                *ngIf="notification.markedAsRead"
                [matTooltip]="'SIDEBAR.READ_AT_TOOLTIP' | translate: { readAt: notification.readAt | luxon: 'DATETIME_MED' }"
                class="text-muted"
                ><mat-icon>visibility</mat-icon></small
            >
        </div>

        <div class="d-flex flex-column">
            <div class="notification-body">{{ notification.body }}</div>
            <small [matTooltip]="notification.createdAt | date: 'short'" class="align-self-end text-muted send-date">{{
                notification.createdAt | luxon: 'fromNow'
            }}</small>
        </div>
    </div>
</div>
